<section>
    <max-width>
        <div class="col lg-row lg-items-center entreprise block-lg">
            <div class="col entreprise-img-container">
                <div class="row items-center entreprise-img-line animated-left">
                    <img
                        src="/images/others/entreprise1.avif"
                        alt="Intérieur d'une Chaufferie"
                        class="entreprise-img"
                        loading="lazy"
                    >
                    <img
                        src="/images/others/entreprise2.avif"
                        alt="Deux hommes discutent sur un chantier"
                        class="entreprise-img"
                        loading="lazy"
                    >
                </div>
                <div class="row items-center entreprise-img-line entreprise-img-m animated-left">
                    <img
                        src="/images/others/entreprise3.avif"
                        alt=""
                        class="entreprise-img"
                        loading="lazy"
                    >
                    <img
                        src="/images/others/entreprise4.avif"
                        alt="De l'eau sort d'un robinet"
                        class="entreprise-img"
                        loading="lazy"
                    >
                </div>
            </div>
            <div class="col color">
                <h2 class="entreprise-h2 animated-bottom">
                    L'entreprise
                </h2>
                <p class="animated-bottom">
                    Depuis sa création en 2005, notre société met les compétences de ses techniciens et ingénieurs au service des gestionnaires et exploitants de réseaux d'eau privés.
                </p>
                <div class="row items-center entreprise-img-container-mobile">
                    <img
                        src="/images/others/entreprise3.avif"
                        alt=""
                        class="entreprise-img-mobile"
                        loading="lazy"
                    >
                    <img
                        src="/images/others/entreprise4.avif"
                        alt="De l'eau sort d'un robinet"
                        class="entreprise-img-mobile"
                        loading="lazy"
                    >
                </div>
                <p class="animated-bottom">
                    Nous accompagnons nos clients dans la résolution de toutes les problématiques liées à la gestion des réseaux d'eau.
                </p>
                <p class="animated-bottom">
                    Notre équipe est composée de
                    <span class="bold">
                        23 personnes
                    </span>
                    ${" "}et
                    <span class="bold">
                        3 agences
                    </span>
                    ${" "}réparties sur l'ensemble du territoire métropolitain : Marseille, Paris et Toulouse.
                </p>
            </div>
        </div>
    </max-width>
</section>

style {
    .entreprise {
        gap: 100px;
    }
    .bold {
        color: #111827;
        font-weight: 700;
    }
    .color {
        text-align: justify;
        gap: 36px;
        color: #6b7280;
        font-size: 20px;
        line-height: 28px;
    }
    .entreprise-h2 {
        color: #111827;
        text-transform: uppercase;
        font-size: 32px
    }
    .entreprise-img {
        height: 170px;
    }
    .entreprise-img-container {
        display: none;
    }
    .entreprise-img-line {
        gap: 24px;
    }
    .entreprise-img-container-mobile {
        gap: 12px;
    }
    .entreprise-img-mobile {
        width: 50%;
    }
    .entreprise-img-m {
        margin-left: 50px;
    }
    @media (min-width: 1024px) {
        .entreprise-h2 {
            font-size: 48px;
        }
        .entreprise-img-container {
            display: flex;
            gap: 24px;
        }
        .color {
            text-align: justify;
            gap: 36px;
            color: #6b7280;
        }
        .entreprise-img-container-mobile{
            display: none;
        }
    }
}
