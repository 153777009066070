import info from "../../info.json";

<section id="nos-services" key="nos-services" class="services-section">
    <max-width>
        <div class="col services block-lg">
            <h2 class="services-h2 animated-bottom">
                Nos services
            </h2>
            <ul class="services-ul wrap content-center">
                <for|service| of=info.services>
                    <li class="animated-right" key=service.id>
                        <div class="services-li">
                            <a
                                class="col full-center services-a"
                                href=`/nos-services/${service.slug}`
                            >
                                <span>${service.title}</span>
                                <img
                                    src=service.icon
                                    alt=service.icon_alt
                                    class="services-icon"
                                >
                            </a>
                        </div>
                    </li>
                </for>
            </ul>
        </div>
    </max-width>
</section>

style {
    .services-section {
        background: center / cover no-repeat
            url("/images/others/bg-activities.avif");
    }
    .services {
        gap: 32px;
    }
    .services-h2 {
        color: white;
        text-transform: uppercase;
        text-align: center;
        font-size: 32px
    }
    .services-ul {
        list-style: none;
        gap: 24px;
    }
    .services-li {
        background: white;
        border-radius: 8px;
    }
    .services-li:active {
        opacity: 80%;
    }
    .services-a {
        text-decoration: none;
        color: #025fae;
        gap: 24px;
        padding: 12px;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        width: 300px;
        height: 180px;
    }
    .services-icon {
        width: 60px;
    }
    @media (min-width: 1024px) {
        .services {
            gap: 68px;
        }
        .services-h2 {
            font-size: 48px;
        }
        .services-ul {
            list-style: none;
            gap: 32px;
        }
        .services-li {
            transition: 500ms;
        }
        .services-a {
            gap: 24px;
            padding: 12px;
            font-size: 20px;
            line-height: 28px;
            width: 390px;
            height: 230px;
        }
        .services-icon {
            width: 100px;
        }
        .services-li:hover {
            transform: scale(1.05);
        }
    }
}
