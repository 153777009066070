<section id="recrutement" key="recrutement">
    <max-width>
        <div class="row evenly-center hiring block-lg">
            <div class="col hiring-text">
                <h2 class="hiring-h2 animated-bottom">
                    Nous recrutons
                </h2>
                <p class="animated-bottom">
                    Notre société est à la recherche d'ingénieurs et de techniciens (bac +2 ou bac +5) curieux, observateurs, autonomes, mobiles, avec ou sans expérience.
                </p>
                <div class="col hiring-img-container-mobile">
                    <img
                        src="/images/logos/Ile-de-France.avif"
                        alt=""
                        class="hiring-img hiring-img-center"
                    >
                    <img
                        src="/images/others/selfie.avif"
                        alt=""
                        class="hiring-img-selfie hiring-img-center"
                    >
                    <div class="row around-center">
                        <img
                            src="/images/logos/Sud-Ouest.avif"
                            alt=""
                            class="hiring-img"
                        >
                        <img
                            src="/images/logos/Sud-Est.avif"
                            alt=""
                            class="hiring-img"
                        >
                    </div>
                </div>
                <p class="animated-bottom">
                    Intérêt requis pour la microbiologie, la thermique, l'hydraulique, le traitement de l'eau. Avec ou sans expérience.
                </p>
                <p class="animated-bottom">
                    Pour soumettre une candidature, contactez-nous via notre formulaire de contact ou directement sur <span class="bold">contact@audit-process.fr</span>.
                </p>
            </div>
            <div class="col hiring-img-container">
                <img
                    src="/images/logos/Ile-de-France.avif"
                    alt=""
                    class="hiring-img hiring-img-center animated-right"
                >
                <img
                    src="/images/others/selfie.avif"
                    alt=""
                    class="hiring-img-selfie hiring-img-center animated-right"
                >
                <div class="row between-center animated-right">
                    <img
                        src="/images/logos/Sud-Ouest.avif"
                        alt=""
                        class="hiring-img"
                    >
                    <img
                        src="/images/logos/Sud-Est.avif"
                        alt=""
                        class="hiring-img"
                    >
                </div>
            </div>
        </div>
    </max-width>
</section>

style {
    .hiring {
        gap: 100px;
    }
    .hiring-h2 {
        font-size: 32px;
        color: #111827;
        text-transform: uppercase;
    }
    .hiring-text {
        text-align: justify;
        gap: 24px;
        color: #6b7280;
        font-size: 20px;
        line-height: 28px;
    }
    .hiring-img-container {
        display: none;
    }
    .hiring-img-container-mobile {
        width: 100%;
        gap: 12px;
    }
    .hiring-img {
        height: 30px;
    }
    .hiring-img-selfie {
        width: 300px;
    }
    .hiring-img-center {
        margin-inline: auto;
    }
    @media (min-width: 1024px) {
        .hiring-img-container {
            display: flex;
            gap: 36px;
        }
        .hiring-h2 {
            font-size: 48px;
            line-height: 1;
        }
        .hiring-text {
            flex-basis: 50%;
        }
        .hiring-text {
            text-align: justify;
            gap: 36px;
            color: #6b7280;
            font-size: 20px;
            line-height: 28px;
        }
        .hiring-img-container-mobile {
            display: none;
        }
        .hiring-img-selfie {
            width: 400px;
        }
        .hiring-img {
            height: 40px;
        }
    }
}
