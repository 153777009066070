<section class="intro" id="accueil">
    <div class="filter">
        <max-width>
            <div class="col full-center content">
                <div class="circlee animated-left row full-center">
                    /*<svg
                        width=300
                        height=300
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cy="50%"
                            cx="50%"
                            r=120
                            stroke="currentColor"
                            stroke-width="10"
                            fill="transparent"
                        />
                    </svg>*/
                    <img
                        src="/images/logos/Logo-Audit-Process-solo.webp"
                        alt="Logo Audit Process"
                        class="intro-logoe"
                    >
                </div>
                <h1 class="animated-right">
                    Audit Process
                </h1>
                <p class="subtitle animated-bottom">
                    Bureau d'études spécialisé dans le domaine de l'hydraulique
                </p>
            </div>
        </max-width>
    </div>
</section>

style {
    .intro {
        padding: 0;
        width: 100%;
        background: center / cover no-repeat
            url("/images/others/water-drop.avif");
    }
    .filter {
        z-index: 1;
        width: 100%;
        background: linear-gradient(to right, black, transparent);
    }
    .content {
        height: auto;
        width: 100%;
        padding-block: 150px;
        color: white;
        gap: 20px;
    }
    .circle {
        position: relative;
    }
    .circlee {
        border-radius: 9999px;
        border: solid 6px white;
        width: 150px;
        height: 150px;
        position: relative;
    }
    .intro-logoe {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-35%, -50%);
        width: 45%;
    }
    .intro-logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-35%, -50%);
        width: 35%;
    }
    h1 {
        font-size: 36px;
        line-height: 40px;
        text-transform: uppercase;
        font-weight: 800;
    }
    .subtitle {
        text-align: center;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
    }
    @media (min-width: 640px) {
        h1 {
            font-size: 60px;
            line-height: 1;
            font-weight: 800;
        }
        .subtitle {
            width: 400px;
            font-size: 30px;
            line-height: 36px;
            font-weight: 600;
        }
        .circlee {
            border: solid 8px white;
            width: 230px;
            height: 230px;
        }
    }
    @media (min-width: 1024px) {
        h1 {
            font-size: 96px;
            line-height: 1;
            font-weight: 800;
        }
        .subtitle {
            width: 600px;
            font-size: 36px;
            line-height: 40px;
            font-weight: 600;
        }
        .content {
            height: 100vh;
            gap: 20px;
        }
        .circlee {
            border: solid 10px white;
            width: 300px;
            height: 300px;
        }
    }
}
