class {
    onCreate() {
        this.state = {
            isNavOff: false,
        };
    }
    onMount() {
        const header = document.querySelector("header");
        const navUnicode = document.querySelector(".nav-unicode");
        const navContact = document.querySelector(".nav-contact");
        const navAs = document.querySelectorAll(".nav-a");
        const headerHeight = header.clientHeight;

        this.state.isNavOff = headerHeight < window.scrollY || document.documentElement.scrollTop;
     
        const switchNavOff = () => {
            let scrollTop = window.scrollY || document.documentElement.scrollTop;
            if (headerHeight > scrollTop) {
                header.classList.add("header-index");
                navUnicode.classList.add("nav-unicode-index");
                navContact.classList.add("nav-contact-index");
                navAs.forEach((a) => {
                    a.classList.add("nav-a-index")
                    if (a.classList.contains("nav-a-selected")) {
                        a.classList.remove("nav-a-selected");
                        a.classList.add("nav-a-index-selected");
                    }
                });
                this.state.isNavOff = false;
            } else {
                header.classList.remove("header-index");
                navUnicode.classList.remove("nav-unicode-index");
                navContact.classList.remove("nav-contact-index");
                navAs.forEach((a) => {
                    a.classList.remove("nav-a-index");
                    if (a.classList.contains("nav-a-index-selected")) {
                        a.classList.remove("nav-a-index-selected");
                        a.classList.add("nav-a-selected");
                    }
                });
                this.state.isNavOff = true;
            }
        };
        window.addEventListener("load", switchNavOff);
        window.addEventListener("scroll", switchNavOff);
    }
    onDestroy() {
        window.removeEventListener("load", switchNavOff);
        window.removeEventListener("scroll", switchNavOff);
    }
}

<app-header isNavOff=state.isNavOff/>
<main>
    <index-intro/>
    <index-entreprise/>
    <index-services/>
    <index-hiring/>
</main>
